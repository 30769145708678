<template>
  <section class="result-subjects">
    <Wrapper>
      <StudyGuideVideoModal
        v-if="videoModalVisible"
        @close="closeStudyGuideVideo()"
        @open-study-guide="goToStudyGuide()"
      />

      <RankedKnowledgeAreasPerformance
        v-if="isEnem"
        :assessment-id="assessmentId"
        :exam-id="examId"
      />

      <RankedCategoryPerformance
        v-if="!isDigitalEnem"
        :assessment-id="assessmentId"
        :exam-id="examId"
        :is-high-school="isGradedByIrt"
      />

      <RankedCategoryCharts :exam-id="examId" />
    </Wrapper>
  </section>
</template>

<script>
import md5 from 'blueimp-md5'
import { mapGetters } from 'vuex'

import RankedCategoryPerformance from '@/components/RankedCategoryPerformance'
import RankedCategoryCharts from '@/components/RankedCategoryCharts'

import { getAuthSession } from '@/utils/auth'
import accountApi from '@/api/account'
import urls from '@/utils/urls'

const RankedKnowledgeAreasPerformance = () => import('@/components/RankedKnowledgeAreasPerformance')
const StudyGuideVideoModal = () => import('@/components/StudyGuideVideoModal')

export default {
  name: 'ResultSubjects',
  components: {
    StudyGuideVideoModal,
    RankedKnowledgeAreasPerformance,
    RankedCategoryPerformance,
    RankedCategoryCharts,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.$emit('select-tab', vm.$route.name))
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    examId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      videoModalVisible: false,
      historicalUserId: 0,
    }
  },
  computed: {
    ...mapGetters([
      'isGradedByIrt',
      'user',
      'assessment',
      'exam',
      'isDigitalEnem',
      'isEnem',
    ]),
  },
  created() {
    this.loadHistoricalUserId()
  },
  methods: {
    async loadHistoricalUserId() {
      try {
        const { data } = await accountApi.fetchUserHistorical()
        const userHistorical = data
        const { id } = userHistorical.find(
          ({ year }) => year === this.assessment.year,
        )
        this.historicalUserId = id
      } catch (error) {
        this.$toasted.global.error({
          message: 'Ocorreu um erro ao carregar informarções do aluno.',
          errorCode: error.response ? error.response.status : '',
        })
      }
    },
    goToStudyGuide() {
      this.closeStudyGuideVideo()

      const host = urls.RANKED_STUDY_GUIDE_URI
      const portalToken = getAuthSession()
      const assessmentId = this.assessment.legacyAssessmentId
      const schoolIdHash = md5(this.user.school.id)
      const username = encodeURI(this.user.name)
      const gradeName = encodeURI(this.assessment.grade)
      const examId = this.exam.legacyExamId
      const userId = this.historicalUserId
      const { edition } = this.assessment
      const query = `?simulado=${assessmentId}&token=${portalToken}&tokenAuth=${schoolIdHash}&nome=${username}&serie=${gradeName}&numeroAvaliacao=${edition}&prova=${examId}&alunoId=${userId}`

      window.open(`${host}${query}`, '_blank')
    },
    openStudyGuideVideo() {
      this.videoModalVisible = true
    },
    closeStudyGuideVideo() {
      this.videoModalVisible = false
    },
  },
}
</script>

<style lang="sass">
.result-subjects
  margin-top: 40px
  margin-bottom: 40px

  .wrapper
    +mq-m
      max-width: 100%

  section ~ section
    margin-top: 56px

    +mq-s
      margin-top: 40px

  & > section > h3
    margin-bottom: 24px
    box-sizing: border-box

    +mq-m
      padding: 0 24px

    +mq-s
      padding: 0 16px
</style>
