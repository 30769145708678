import api from '@/api/http'
import { setAuthCookies } from '@/utils/auth'
import urls from '@/utils/urls'

import AuthTokenServiceInstance from '@/crossIntegration/AuthTokenService'
import tokensFromCookies from '@/crossIntegration/utils/tokensFromCookies'
import { getCrossAuthToken } from '@/crossIntegration/utils/userInfo'
import { clearSASTokenData } from './auth'

const config = {
  baseURL: urls.EUREKA_BASE_API,
}

async function getInitialToken() {
  let initialToken = null

  if (!tokensFromCookies.crossToken) {
    initialToken = tokensFromCookies.sasToken || initialToken
  } else {
    if (tokensFromCookies.sasToken) {
      clearSASTokenData()
    }
    AuthTokenServiceInstance.setCurrentToken(getCrossAuthToken({ formatted: true }))

    const payload = {
      token: getCrossAuthToken(),
      isInherited: true,
    }
    const { data } = await api.post('/v1/exchange-token/onb-to-sas/', payload, config)

    setAuthCookies(data.authResponse)

    AuthTokenServiceInstance.setCurrentToken(`Bearer ${data.authResponse.token}`)

    initialToken = `Bearer ${data.authResponse.token}`
  }

  return initialToken
}

export default getInitialToken
