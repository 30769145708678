<template>
  <Wrapper>
    <transition-group
      appear
      class="general-result__basic"
      name="fade"
      tag="div"
    >
      <div key="scoreboard">
        <ExamResultScoreboard
          :assessment-id="assessmentId"
          :exam-id="examId"
        />
      </div>

      <div
        key="hitsboard"
        class="general-result__basic-hits"
      >
        <ExamResultHitsboard
          :application-id="applicationId"
          :assessment-id="assessmentId"
          :exam-id="examId"
          @click="$router.push({
            name: 'examResultItems',
            params,
          })"
        />
      </div>

      <div key="performance">
        <ExamResultPerformanceCategory
          :assessment-id="assessmentId"
          :exam-id="examId"
        />
      </div>
    </transition-group>

    <StudyGuideModal
      v-if="isModalVisible"
      key="studyGuideModal"
      @close="isModalVisible = false"
    />
  </Wrapper>
</template>

<script>
import ExamResultScoreboard from '@/components/ExamResultScoreboard'
import ExamResultHitsboard from '@/components/ExamResultHitsboard'
import ExamResultPerformanceCategory from '@/components/ExamResultPerformanceCategory'

const StudyGuideModal = () => import('@/components/StudyGuideModal')

export default {
  name: 'ExamResultGeneralBasic',

  components: {
    ExamResultScoreboard,
    ExamResultHitsboard,
    ExamResultPerformanceCategory,
    StudyGuideModal,
  },

  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },

    applicationId: {
      type: [Number, String],
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      isModalVisible: false,
    }
  },

  computed: {
    params() {
      const { assessmentId, applicationId, examId } = this.$route.params

      return {
        assessmentId,
        applicationId,
        examId,
      }
    },
  },
}
</script>

<style lang="sass">

.general-result__basic
  margin-top: 40px

  &-hits
    margin-top: 24px

  .study-guide-banner
    margin: 16px 0 48px 0

</style>
