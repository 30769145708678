import httpCodes from '@/enums/httpCodes'
import { deleteCookie } from '@/utils/cookies'
import urls from '@/utils/urls'

export const getPortalURLData = (isFromCross = true) => {
  if (!isFromCross) {
    return {
      portalURL: urls.PORTAL_URL,
      redirectParam: 'redirect',
      loginPath: '/login',
    }
  }

  return {
    portalURL: urls.CROSS_PORTAL_URL,
    redirectParam: 'redirectTo',
    loginPath: '/entrar',
  }
}

export const redirectToLogin = ({ redirectBack, isFromCross }) => {
  const { href } = window.location
  const normalizedURL = (href.endsWith('/') ? href.slice(0, Math.max(0, href.length - 1)) : href)
    .split('?')[0]

  let parameters = ''

  const { portalURL, redirectParam, loginPath } = getPortalURLData(isFromCross)

  if (redirectBack && normalizedURL !== portalURL && normalizedURL !== loginPath) {
    parameters += `?${redirectParam}=${encodeURIComponent(href)}`
  }

  window.location.assign(`${portalURL}${parameters}`)
}

export function clearSASTokenData() {
  const sasTokenCookieName = 'token'
  const refreshTokenCookieName = 'refreshToken'
  const sessionCookieName = 'session'

  deleteCookie(sasTokenCookieName)
  deleteCookie(sessionCookieName)
  deleteCookie(refreshTokenCookieName)
}

export const clearAuthTokens = () => {
  const crossTokenCookieName = 'auth_cookie'

  clearSASTokenData()
  deleteCookie(crossTokenCookieName)
  deleteCookie('strategy')
}

export const logout = (options) => {
  const { redirectBack } = {
    redirectBack: false,
    ...options,
  }

  clearAuthTokens()
  redirectToLogin({
    ...options,
    redirectBack,
  })
}

export const handleRedirection = ({ status, isFromCross }) => {
  const errorsWithPages = [
    httpCodes.serviceUnavailable,
    httpCodes.forbidden,
  ]

  if (!isFromCross) {
    errorsWithPages.push(httpCodes.internalServerError)
  }

  if (status === httpCodes.unauthorized) {
    logout({
      redirectBack: true,
      isFromCross,
    })
  } else if (errorsWithPages.includes(status)) {
    const { portalURL } = getPortalURLData(isFromCross)

    window.location.assign(`${portalURL}/error/${status}`)
  }
}

export default {
  handleRedirection,
}
