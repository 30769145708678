<template>
  <div
    id="app"
    :class="{ '--has-menu': isMenuVisible }"
  >
    <AppMain v-if="!isLoading">
      <router-view />
    </AppMain>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppMain from '@/components/AppMain'

export default {
  name: 'App',
  components: {
    AppMain,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    isMenuVisible() {
      return !this.user.isFromCross
    },
  },
  created() {
    this.addUserToDataLayer()
  },
  methods: {
    ...mapActions(['getUser']),
    async addUserToDataLayer() {
      this.isLoading = true

      await this.getUser()

      const {
        id,
        school,
        profiles,
        grades,
      } = this.user

      window.dataLayer.push({
        accountId: id,
        schoolId: school.id,
        profileId: profiles[0].id,
        gradeId: grades.map((grade) => grade.id),
        prospection: this.$store.state.profile.personalData?.schoolProspection,
      })

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '~@/styles/main';

body {
  background: $color-ice;
  overflow-x: hidden;

  &.lock-scroll {
    overflow: hidden;
  }

  #app.--has-menu {
    padding-top: $size-xxl;
  }
}
</style>
